@import 'src/theme/constants.scss'
@import 'src/theme/mixins.sass'

.root
  display: flex
  flex-direction: column
  align-items: stretch
  overflow: auto
  border-radius: $big-border-radius
  background: $primaryCardBGColor
  padding: 16px 24px
  gap: 8px

.editor
  display: flex
  flex-direction: column
  gap: 16px
  border-top: 1px solid $dividerLineLightColor
  padding-top: 16px
  overflow: auto

.loader
  margin: auto

.control
  display: flex
  gap: 16px

.answerContainer
  display: flex
  padding-block: 16px
  padding-inline: 8px
  border-radius: $medium-border-radius
  background: $text10Color
  min-height: 120px
  overflow: auto

.content
  padding-inline: 8px
  overflow: auto
  @include thinScroll('&', 5px)

.verified
  display: flex
  flex-direction: column
  gap: 4px

.alignCenter
  display: flex
  align-items: center
  gap: 4px
