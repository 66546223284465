@import 'src/theme/constants.scss'

@keyframes fadeIn
  from
    opacity: 0
  to
    opacity: 1

.root
  display: flex
  background: $primaryCardBGColor
  border-radius: 16px
  border: 1px solid transparent
  min-height: 250px
  padding: 8px
  cursor: pointer
  flex-direction: column
  gap: 16px
  animation: fadeIn 0.5s ease-in-out
  &:hover
    box-shadow: 2px 2px 10px 0px $primary50Color
    border: 1px solid $primary100Color

.illustrationContainer
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 216px
  background: $primaryCardL3BGColor
  border-radius: 8px
  overflow: hidden
  max-height: 216px
  min-height: 216px
  color: $primary70_20Color
  i
    font-size: 70px

  &.dashBorder
    border: 2px dashed $secondary100Color

.illustration
  height: 100%
  width: 100%
  object-fit: cover

.body
  display: flex
  flex-direction: column
  flex: 1 1 100%
  height: 100%
  gap: 16px
  width: 100%

.title
  display: flex
  justify-content: space-between
  align-items: flex-start
  gap: 16px

.description
  flex: 1 1 100%
  text-align: start
  font-size: 12px
  font-weight: 400
  color: $mainText100Color

.cta
  display: flex
  justify-content: flex-end
  gap: 0.4em

  i
    font-size: 1.5em

.betaContainer
  display: flex
  flex-direction: column
  gap: 8px

@media (max-width: $mobile-min-width)
  .root
    flex-direction: column
    min-height: auto
    gap: 16px

  .illustration
    margin-right: 0
    margin-bottom: 16px
    width: 100%

@media (min-width: $tablet-max-height) and (max-width: $tablet-min-width)
  .title
    font-size: 12px
  .description
    font-size: 10px
