@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  min-width: 450px
  padding: 28px
  background: $primaryDrawerBGColor

.closeButton
  position: absolute
  right: calc(100% + 20px)
  top: 20px

@media (max-width: $mobile-min-width)
  .root
    min-width: 100%
    padding: 32px 0px
    border-radius: 0px

  .closeButton
    right: calc(100% - 64px)
    top: 24px
    background: transparent
    font-size: 24px
