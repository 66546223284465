@import 'src/theme/colors.scss'

.root
  display: grid
  grid-auto-flow: column
  grid-template-columns: auto
  grid-auto-rows: auto
  border-radius: 25px
  padding-block: 0
  padding-inline: 24px
  justify-content: center
  align-items: center
  color: $primaryContrast100Color
  cursor: pointer

  --hover-bg-color: transparent
  --btn-min-height: 32px
  --btn-padding: 24px

  &:disabled
    opacity: 0.3
    cursor: default

.root.noPadding
  padding: 0

.before
  margin-right: .5em
.after
  margin-left: .5em
