@import 'src/theme/mixins.sass'

.root
  display: flex
  flex-direction: column
  overflow: hidden
  gap: 16px

.body
  overflow: auto
  @include thinScroll('&')

.footer
  display: flex
  justify-content: space-between
  align-items: center
  gap: 16px

.footerText
  display: flex
  gap: 8px

.infoIcon
  margin-right: 5px

.buttonContainer
  display: flex
  gap: 8px
  &>*
    min-width: 100px
