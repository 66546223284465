@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'
  
.root
  display: flex
  flex-direction: column
  gap: 36px
  width: 400px
  height: 100%

.list
  flex: 1 1 auto
  gap: 24px
  display: flex
  flex-direction: column
  overflow-y: auto

.headingWrapper
  button
    display: none

.question
  padding: 8px
  border-bottom: 1px solid $dividerLineLightColor
  cursor: pointer
  &:hover
    border-radius: 8px
    background-color: $primaryContrast10OpacityColor
    border-bottom: 1px solid transparent

.footer
  flex: 0
  display: flex
  justify-content: center
  
.footer
  visibility: hidden

@media(max-width: $tablet-max-width)
  .footer
    visibility: visible

@media (max-width: $mobile-min-width)
  .root
    width: 100%

@media (max-width: $tablet-max-height)
  .root
    padding: 40px 16px 16px
  .headingWrapper
    display: flex
    justify-content: space-between
    button
      display: inline-block
