@import "@root/theme/colors.scss"
@import '@root/theme/mixins.sass'

.root
  width: 100%

.container
  display: flex
  width: 100%
  margin-top: 42px
  gap: 32px
  .mainContent
    flex: 1

  .linkedContent
    max-height: 800px
    width: 30%
    display: flex
    flex-direction: column
    padding: 16px
    gap: 24px

.header
  margin-top: 24px
  margin-bottom: 8px

.mainHeading
  margin-bottom: 8px
  margin-top: 8px

.identifiers
  display: flex
  gap: 8px
  flex-wrap: wrap

.attributes, .identifiers
  margin-top: 16px

.card
  padding: 8px
  border-radius: 16px
  background: $primaryCardBGColor

table 
  > thead > tr > td
    border-bottom: 2px solid $dividerLineLightColor !important
  td, th
    padding: 1.5em !important
.linkedContentList
  display: flex
  overflow-x: hidden
  flex-direction: column
  gap: 16px
  max-height: 800px
  overflow-y: auto
  @include thinScroll('&', 2px)

.content
  display: flex
  justify-content: space-between
  background: $secondaryButtonBgColor
  border-radius: 8px
  gap: 8px
  font-size: 14px
  padding: 8px
  cursor: pointer
  border: 1px solid transparent
  span
    word-break: break-all
  &:hover
    border: 1px solid $primary80Color

.loader
  display: flex
  width: 100%
  justify-content: center

.nameSkeleton
  width: 100px
